import { Box, Button, Grid } from "@mui/material"
import { Link } from "react-router-dom"
import classes from "./Contractors.module.css"

export const Contractors = () => {
    return (
        <>
            <Grid container className={classes.container_spacing} columns={{ xs: 4, sm: 6, md: 12 }}>
                <Grid item xs={6} sm={6} md={6} className={classes.leftContainer_spacing}>
                    <Box component='h1' className={classes.page_heading}>Looking for Contract Developers?</Box>
                    <Box component='p' className={classes.page_subHeading}>Hire Bluedot contract engineers based in the US or Canada, to work onsite or remote</Box>
                    <Link to={'/start-hiring'} className={classes.text_decoration}><Button variant="contained">Start Hiring</Button></Link>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Box
                        component='div'
                        className={classes.banner_image}
                    />
                </Grid>
            </Grid>
        </>

    )
}