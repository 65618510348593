import { Authenticator } from '@aws-amplify/ui-react';
import Grid from '@mui/material/Grid';
export const Login = () => {
    return (
        <>
            <Grid container spacing={2} sx={{ m: 2 }}>
                <Grid item xs={6} >
                    <h1>Meet with a Bluedot Talent Specialist</h1>
                    <p>Whether you are ready to hire or just want to learn more about how Bluedot works, you can schedule a call with a Talent Specialist at Bluedot by completing the request form to the right. Once submitted, we'll contact you to schedule a call ASAP.</p>
                </Grid>
                <Grid item xs={6}>
                    <div>
                        <Authenticator>
                            {({ signOut, user }) => (
                                <main>
                                    <h1>Hello {user.username}</h1>
                                    <button onClick={signOut}>Sign out</button>
                                </main>
                            )}
                        </Authenticator>
                    </div>
                </Grid>
            </Grid>
        </>

    );
}
