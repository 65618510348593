import { Box, Button, Grid } from "@mui/material"
import { Link } from "react-router-dom";
import classes from './Home.module.css';

const Home = () => {
    return (
        <>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 1, sm: 1, md: 2 }} className={classes.container_spacing}>
                <Grid item xs={1} sm={1} md={1} className={classes.leftContainer_spacing}>
                    <Box component='h1' className={classes.page_heading}>Our engineers help you build the products that scale</Box>
                    <Box component='p' className={classes.page_subHeading}>Get your development going.</Box>
                    <Link to={'/start-hiring'} className={classes.text_decoration}><Button variant="contained">Start Hiring</Button></Link>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={classes.rightContainer_spacing}>
                    <Box
                        component='div'
                        className={classes.banner_image}
                    />
                </Grid>
            </Grid>
        </>
    )
}
export default Home;