import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Divider,
  Drawer,
  IconButton,
  useScrollTrigger,
  ListItemText,
  ListItem,
  List,
  Collapse,
  ListItemButton,
} from "@mui/material";
import classes from "./Navbar.module.css";

const pages = [
  {
    name: "Hire",
    path: "/recruiting",
    id: "m1",
    children: [
      {
        name: "Recruiting",
        path: "/recruiting",
      },
      {
        name: "Contractors",
        path: "/contractors",
      },
      {
        name: "Nearshore",
        path: "/nearshore",
      },
    ],
  },
  {
    name: "Find work",
    path: "/",
    id: "m2",
    children: [
      {
        name: "Full-Time",
        path: "/fulltime",
      },
      {
        name: "Contract",
        path: "/contract",
      },
    ],
  },

  {
    name: "Contact us",
    path: "/contact",
    id: "m3",
    children: [],
    sx: {
      color: "black",
    },
  },

  // {
  //     name: 'Community',
  //     path: '/community',
  //     children: []
  // },
  // {
  //     name: 'Login',
  //     path: '/login',
  //     children: []
  // },
  {
    name: "Start Hiring",
    path: "/start-hiring",
    children: [],
    sx: { my: 2, mx: 1, display: "block" },
    variant: "contained",
  },
];

const ElevationScroll = (props) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const StyledButton = styled(Button)`
  &:hover {
  }
`;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "rgba(25, 118, 210, 0.04) !important",
    },
  },
}));

const NavBar = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openIndex, setOpenIndex] = React.useState(-1);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [collapseopen, setCollapseOpen] = React.useState("");
  const open = Boolean(anchorEl);
  const handleClick = (index) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenIndex(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const toggleDrawer = () => {
    setOpenDrawer(true);
  };
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleCollapse = (name) => {
    setCollapseOpen(name);
  };

  return (
    <ElevationScroll {...props}>
      <AppBar className={classes.Appbar_container}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{ flexGrow: 1, mr: 2, display: { xs: "none", md: "flex" } }}
            >
              <Link to="/" className={classes.link}>
                blue.
              </Link>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <Link to="/" className={classes.link}>
                blue.
              </Link>
            </Box>
            <Box
              sx={{
                flexGrow: 2,
                display: { xs: "flex", md: "none" },
                justifyContent: "flex-end",
              }}
            >
              <Drawer
                variant="temporary"
                anchor="right"
                open={openDrawer}
                onClose={handleDrawerClose}
              >
                <Box sx={{ width: 250 }} role="presentation">
                  <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                      <ChevronRightIcon />
                    </IconButton>
                  </DrawerHeader>
                  <Divider />
                  <List>
                    {pages.map((page, index) =>
                      !page.children.length ? (
                        <Link
                          to={page.path}
                          style={{ textDecoration: "none", color: "black" }}
                          key={page.name + "individual"}
                        >
                          <ListItem button onClick={handleDrawerClose}>
                            <ListItemText primary={page.name} />
                          </ListItem>
                        </Link>
                      ) : (
                        <div key={page.name + "parent"}>
                          <ListItemButton
                            onClick={() => handleCollapse(page.name)}
                          >
                            <ListItemText primary={page.name} />
                            {collapseopen === page.name ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItemButton>
                          <Collapse
                            in={collapseopen === page.name}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {page.children.map((child, index) => (
                                <Link
                                  to={child.path}
                                  key={child.name + "child"}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                >
                                  <ListItemButton
                                    onClick={handleDrawerClose}
                                    sx={{ pl: 4 }}
                                  >
                                    <ListItemText primary={child.name} />
                                  </ListItemButton>
                                </Link>
                              ))}
                            </List>
                          </Collapse>
                        </div>
                      )
                    )}
                  </List>
                </Box>
              </Drawer>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {pages.map((page, menuindex) =>
                !page.children.length ? (
                  <Link
                    to={page.path}
                    key={page.name + "individual"}
                    style={{ textDecoration: "none", alignSelf: "center" }}
                  >
                    <StyledButton
                      onClick={handleCloseNavMenu}
                      disableRipple
                      variant={page.variant}
                      sx={{ ...page.sx }}
                      disableElevation
                    >
                      {page.name}
                    </StyledButton>
                  </Link>
                ) : (
                  <Box sx={{ display: "flex" }} key={page.name + "parent"}>
                    <StyledButton
                      id={page.name}
                      aria-controls={page.name}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      sx={{ color: "black" }}
                      disableElevation
                      disableRipple
                      onClick={handleClick(menuindex)}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      {page.name}
                    </StyledButton>
                    <StyledMenu
                      id={page.name}
                      MenuListProps={{
                        "aria-labelledby": page.name,
                      }}
                      sx={{ color: "black" }}
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && menuindex === openIndex}
                      onClose={handleClose}
                    >
                      {page.children.map((child, index) => (
                        <Link
                          to={child.path}
                          key={child.name + "child"}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <MenuItem onClick={handleClose} disableRipple>
                            {child.name}
                          </MenuItem>
                        </Link>
                      ))}
                    </StyledMenu>
                  </Box>
                )
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
};
export default NavBar;
