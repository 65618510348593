import { Fragment } from 'react'
import { Footer } from './Footer'
import NavBar from './NavBar'
import classes from './Layout.module.css';
export const Layout = (props) => {
    return (
        <div className={classes.wrapper}>
            <NavBar />
            <main>{props.children}</main>
            {/* <Footer /> */}
        </div>
    )
}