import Amplify from "aws-amplify";
// { API, graphqlOperation }
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
// import UbuntuLight from 'Fonts/Ubuntu-Light.ttf'
import { Login } from "./Pages/Login";
import Home from "./Pages/Home";
import { Recruiting } from "./Pages/Hire/Recruiting";
import { Contractors } from "./Pages/Hire/Contractors";
import { StartHiring } from "./Pages/Hire/StartHiring";
import { NearShore } from "./Pages/Hire/NearShore";
import { FullTime } from "./Pages/FindWork/FullTime";
import { Contract } from "./Pages/FindWork/Contract";
import { Layout } from "./Components/Layout";
import { NotFound } from "./Pages/NotFound";
import Community from "./Pages/Community";
import { ThemeProvider, createTheme } from "@mui/material";
import { FutureEmployee } from "./Pages/FindWork/FutureEmployee";
import { SuccessPage } from "./Pages/Hire/successPage";
import ContactUs from "./Pages/ContactUs";

Amplify.configure(awsExports);
const theme = createTheme({
  typography: {
    fontFamily: "'Roboto',  sans-serif",
  },
});
export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/start-hiring" element={<StartHiring />} />
            <Route path="/community" element={<Community />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/recruiting" element={<Recruiting />} />
            <Route path="/contractors" element={<Contractors />} />
            <Route path="/nearshore" element={<NearShore />} />
            <Route path="/contract" element={<Contract />} />
            <Route path="/fulltime" element={<FullTime />} />
            <Route path="/future-employee" element={<FutureEmployee />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  );
}
