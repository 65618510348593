import * as React from 'react';
import { alpha, styled } from '@mui/system';
import { InputBase } from '@mui/material';

const Input = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '100%',
        padding: '6px 7px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

// const StyledInputElement = styled('input')(
//     ({ theme }) => `
//   width: 100%;
//   font-size: 0.875rem;
//   font-family: IBM Plex Sans, sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
//   background: white;
//   border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
//   border-radius: 5px;
//   padding: 8px 8px;
//   transition: all 150ms ease;

//   &:hover {
//     background: white;
//     border-color: #3898ec;
//   }

//   &:focus {
//     outline: 1px solid #3898ec;
//   }
// `,
// );

export const CustomInput = React.forwardRef(function CustomInput(props, ref) {
    return (
        // <InputUnstyled components={{ Input: StyledInputElement }} {...props} ref={ref} />
        <Input {...props} ref={ref} />
    );
});
