import { useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from '@emailjs/browser';


const initialFormValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    company: "",
    describe: "",
    plan: "",
    email: "",
    message: "",
    // fullTime: true,
    // contractors: false,
    formSubmitted: false,
}

const useForm = () => {
    // #####
    const navigate = useNavigate()
    // We'll update "values" as the form updates
    const [values, setValues] = useState(initialFormValues);
    // "errors" is used to check the form for errors
    const [errors, setErrors] = useState({});
    const [IsFormValid, setIsFormValid] = useState(false);
    const validate = (fieldValues = values) => {
        // this function will check if the form values are valid
        let temp = { ...errors }


        if ("firstName" in fieldValues)
            temp.firstName = fieldValues.firstName ? "" : "This field is required."

        if ("lastName" in fieldValues)
            temp.lastName = fieldValues.lastName ? "" : "This field is required."

        if ("phoneNumber" in fieldValues) {
            temp.phoneNumber = fieldValues.phoneNumber ? "" : "This field is required."
            if (fieldValues.phoneNumber)
                temp.phoneNumber = /^[0-9]{1}[0-9]{9}$/im.test(fieldValues.phoneNumber) ? "" : "Phone Number is not valid."
        }
        // if ("fullTime" in fieldValues) {
        //     temp.fullTime = fieldValues.contractors === true ? "" : "This field is required."
        //     if (!values.fullTime && !values.contractors) {
        //     }
        // }

        // if ("contractors" in fieldValues) {
        //     temp.fullTime = fieldValues.contractors === true ? "" : "This field is required."
        //     if (!values.fullTime && !values.contractors) {
        //     }
        // }



        if ("company" in fieldValues)
            temp.company = fieldValues.company ? "" : "This field is required."

        if ("plan" in fieldValues) {
            temp.plan = fieldValues.plan ? "" : "This field is required."
            if (fieldValues.plan)
                temp.plan = /^[0-9]+$/.test(fieldValues.plan)
                    ? ""
                    : "Not a valid number"
        }

        if ("email" in fieldValues) {
            temp.email = fieldValues.email ? "" : "This field is required."
            if (fieldValues.email)
                temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
                    ? ""
                    : "Email is not valid."
        }

        if ("describe" in fieldValues)
            temp.describe =
                fieldValues.describe ? "" : "This field is required."

        setErrors({
            ...temp
        });
    }
    const handleInputValue = (e) => {
        // this function will be triggered by the text field's onBlur and onChange events
        const { name, value } = e.target;
        if (e.target.type === 'checkbox') {
            setValues(s => ({ ...s, [e.target.name]: !s[e.target.name] }));
            // if (e.target.checked) {
            // } else {
            //     setValues({
            //         ...values,
            //         [e.target.name]: !values[e.target.name],
            //     });
            // }
            // validate({ [e.target.name]: e.target.checked ? true : false });
        }
        setValues({
            ...values,
            [name]: value
        });
        validate({ [name]: value });
        formIsValid(values);
    }
    const handleFormSubmit = (e, form) => {
        // this function will be triggered by the submit event
        e.preventDefault();
        // Emailjs code added
        emailjs.sendForm('service_bhwu8to', 'template_y2zc6tl', form.current, 'user_41DoLzisAckF8ybpnWCMi')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        // e.target.reset()
        // ######
        const isValid =
            Object.values(errors).every((x) => x === "") && formIsValid(values);
        if (isValid) {
            // await postContactForm(values);
            navigate("../success", { replace: true });
        }
    }
    const formIsValid = (fieldValues) => {
        // this function will check if the form values and return a boolean value
        const isValid =
            fieldValues.firstName &&
            fieldValues.lastName &&
            fieldValues.company &&
            fieldValues.email &&
            fieldValues.plan &&
            fieldValues.phoneNumber &&
            fieldValues.describe &&
            Object.values(errors).every((x) => x === "");
        setIsFormValid(isValid);
        return isValid;
    }
    return {
        handleInputValue,
        handleFormSubmit,
        formIsValid,
        IsFormValid,
        errors,
        values
    };

}

export default useForm