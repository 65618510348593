import React from 'react'

const Community = () => {
    return (
        <div>
            Community
        </div>
    )
}
export default Community;
