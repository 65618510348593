import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import classes from "./successPage.module.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 30,
  p: 4,
};
export const SuccessPage = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid
        container
        className={classes.container_spacing}
        columns={{ xs: 4, sm: 6, md: 12 }}
        sx={{ p: 12 }}
      >
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          className={classes.leftContainer_spacing}
        >
          <Box component="h1" className={classes.page_heading}>
            Thank You
          </Box>
          <Box component="p" className={classes.page_subHeading}>
            Thank you for reaching out to us. We will contact you shortly to
            schedule a call.
          </Box>
          <Box component="p" className={classes.page_subHeading}>
            If you have additional questions, please talk to us.
          </Box>
          <Button variant="contained" onClick={handleOpen}>
            contact us
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.model_backDrop} sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Contact
              </Typography>
              <Box id="modal-modal-description" component="div" sx={{ mt: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <p>Phone No: </p>
                  <a href="tel:623-688-2572">623-688-2572</a>
                  <IconButton aria-label="delete" size="large">
                    <ContentCopyIcon
                      onClick={() => {
                        navigator.clipboard.writeText("623-688-2572");
                      }}
                    />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <p>Email: </p>
                  <a href="mailto:hr@bluedotsol.com">hr@bluedotsol.com</a>
                  <IconButton aria-label="delete" size="large">
                    <ContentCopyIcon
                      fontSize="inherit"
                      onClick={() => {
                        navigator.clipboard.writeText("hr@bluedotsol.com");
                      }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Grid>
        <Grid item xs={6} sm={6} md={6} sx={{ px: 4, pt: 5 }}></Grid>
      </Grid>
    </>
  );
};
