import { Box, Button, FormControl, FormLabel, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useRef } from 'react';
import { CustomInput } from '../../Components/UI/Input';
import useFutureEmployeeForm from './FutureEmployeeForm';
import classes from './FutureEmployee.module.css';

export const FutureEmployee = () => {
    const { handleInputValue, handleFormSubmit, IsFormValid, errors } = useFutureEmployeeForm();
    const form = useRef();

    const handleFormSubmission = (e) => {
        handleFormSubmit(e, form)
    }
    return (
        <>
            <Grid container className={classes.container_spacing} columns={{ xs: 1, sm: 3, md: 12 }}>
                <Grid item xs={2} sm={2} md={2}>
                </Grid>
                <Grid item xs={8} sm={8} md={8} className={classes.center_container}>
                    <Box component='h1' className={classes.page_heading}>Find your next job.</Box>
                    <Box component='p' className={classes.page_subHeading}>We’ll help you to get connected with new opportunities.</Box>
                    <Box
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                        className={classes.form_container}
                    >
                        <form ref={form} noValidate autoComplete="off" onSubmit={handleFormSubmission}>
                            <Box sx={{ py: 1 }}>
                                <FormControl component="div" sx={{ width: '100%' }}>
                                    <FormLabel component="label" sx={{ fontWeight: 600, color: 'black' }}>First Name</FormLabel>
                                    <TextField
                                        id="firstName"
                                        defaultValue=""
                                        name='firstName'
                                        size="small"
                                        onChange={handleInputValue}
                                        onBlur={handleInputValue}
                                        {...(errors['firstName'] && {
                                            error: true,
                                            helperText: errors['firstName']
                                        })}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ py: 1 }}>
                                <FormControl component="div" sx={{ width: '100%' }}>
                                    <FormLabel component="label" sx={{ fontWeight: 600, color: 'black' }}>Last Name</FormLabel>
                                    <TextField
                                        id="lastName"
                                        defaultValue=""
                                        name='lastName'
                                        size="small"
                                        onChange={handleInputValue}
                                        onBlur={handleInputValue}
                                        {...(errors['lastName'] && {
                                            error: true,
                                            helperText: errors['lastName']
                                        })}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ py: 1 }}>
                                <FormControl component="div" sx={{ width: '100%' }}>
                                    <FormLabel component="label" sx={{ fontWeight: 600, color: 'black' }}>Phone Number</FormLabel>
                                    <TextField
                                        id="phoneNumber"
                                        defaultValue=""
                                        name='phoneNumber'
                                        size="small"
                                        onChange={handleInputValue}
                                        onBlur={handleInputValue}
                                        {...(errors['phoneNumber'] && {
                                            error: true,
                                            helperText: errors['phoneNumber']
                                        })}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ py: 1 }}>
                                <FormControl component="div" sx={{ width: '100%' }}>
                                    <FormLabel component="label" sx={{ fontWeight: 600, color: 'black' }}>Email</FormLabel>
                                    <TextField
                                        id="email"
                                        defaultValue=""
                                        name='email'
                                        size="small"
                                        onChange={handleInputValue}
                                        onBlur={handleInputValue}
                                        {...(errors['email'] && {
                                            error: true,
                                            helperText: errors['email']
                                        })}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ py: 1 }}>
                                <FormControl component="div" sx={{ width: '100%' }}>
                                    <FormLabel component="label" sx={{ fontWeight: 600, color: 'black' }}>Upload</FormLabel>
                                    <CustomInput type='file' onChange={handleInputValue}
                                        onBlur={handleInputValue} />
                                </FormControl>
                            </Box>
                            <Box>
                                <Button variant="contained" type="submit" disabled={!IsFormValid}>Submit</Button>
                            </Box>
                        </form>
                    </Box>
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                </Grid>
            </Grid>
        </>
    )
}