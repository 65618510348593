import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useRef, useState } from 'react';
import useForm from './StartHiringForm';
import classes from './StartHiring.module.css';

export const StartHiring = () => {
    //Custom hook call
    const { handleInputValue, handleFormSubmit, IsFormValid, errors, values } = useForm();
    const form = useRef();
    const [state, setState] = useState({
        fullTime: true,
        contractors: false,
    });

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const { fullTime, contractors } = state;
    const error = [fullTime, contractors].filter((v) => v).length === 0;
    const handleFormSubmission = (e) => {
        handleFormSubmit(e, form)
    }
    return (
        <>
            <Grid container className={classes.container_spacing} columns={{ xs: 1, sm: 3, md: 12 }}>
                <Grid item xs={2} sm={2} md={2}>
                </Grid>
                <Grid item xs={8} sm={8} md={8} className={classes.center_container}>
                    <Box component='h1' className={classes.page_heading}>Talk to an expert</Box>
                    <Box component='p' className={classes.page_subHeading}>We’ll help you solve the complexities of recruiting for multiple teams in your business.</Box>
                    <Box
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                        className={classes.form_container}
                    >
                        <form ref={form} noValidate autoComplete="off" onSubmit={handleFormSubmission}>
                            <Box sx={{ py: 1 }}>
                                <FormControl component="div" sx={{ width: '100%' }}>
                                    <FormLabel component="label" sx={{ fontWeight: 600, color: 'black' }}>First Name</FormLabel>
                                    <TextField
                                        id="firstName"
                                        defaultValue=""
                                        name='firstName'
                                        size="small"
                                        onChange={handleInputValue}
                                        onBlur={handleInputValue}
                                        {...(errors['firstName'] && {
                                            error: true,
                                            helperText: errors['firstName']
                                        })}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ py: 1 }}>
                                <FormControl component="div" sx={{ width: '100%' }}>
                                    <FormLabel component="label" sx={{ fontWeight: 600, color: 'black' }}>Last Name</FormLabel>
                                    <TextField
                                        id="lastName"
                                        defaultValue=""
                                        name='lastName'
                                        size="small"
                                        onChange={handleInputValue}
                                        onBlur={handleInputValue}
                                        {...(errors['lastName'] && {
                                            error: true,
                                            helperText: errors['lastName']
                                        })}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ py: 1 }}>
                                <FormControl component="div" sx={{ width: '100%' }}>
                                    <FormLabel component="label" sx={{ fontWeight: 600, color: 'black' }}>Email</FormLabel>
                                    <TextField
                                        id="email"
                                        defaultValue=""
                                        name='email'
                                        size="small"
                                        onChange={handleInputValue}
                                        onBlur={handleInputValue}
                                        {...(errors['email'] && {
                                            error: true,
                                            helperText: errors['email']
                                        })}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ py: 1 }}>
                                <FormControl component="div" sx={{ width: '100%' }}>
                                    <FormLabel component="label" sx={{ fontWeight: 600, color: 'black' }}>Phone number</FormLabel>
                                    <TextField
                                        id="phoneNumber"
                                        defaultValue=""
                                        name='phoneNumber'
                                        size="small"
                                        onChange={handleInputValue}
                                        onBlur={handleInputValue}
                                        {...(errors['phoneNumber'] && {
                                            error: true,
                                            helperText: errors['phoneNumber']
                                        })}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ py: 1 }}>
                                <FormControl component="div" sx={{ width: '100%' }}>
                                    <FormLabel component="label" sx={{ fontWeight: 600, color: 'black' }}>Company</FormLabel>
                                    <TextField
                                        id="company"
                                        defaultValue=""
                                        name='company'
                                        size="small"
                                        onChange={handleInputValue}
                                        onBlur={handleInputValue}
                                        {...(errors['company'] && {
                                            error: true,
                                            helperText: errors['company']
                                        })}
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                {/* <FormControl
                                    required
                                    error={error}
                                    component="fieldset"
                                    sx={{ m: 3 }}
                                    variant="standard"
                                >
                                    <label >I'm looking for help hiring</label>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={fullTime} onChange={handleChange} name="Full-time employees" />} label="Full-time employees" />
                                        <FormControlLabel control={<Checkbox checked={contractors} onChange={handleChange} name="Contractors" />} label="Contractors" />
                                    </FormGroup>
                                    <FormHelperText>You can display an error</FormHelperText>
                                </FormControl> */}
                                {/* <FormControl
                                    aria-required
                                    {...(errors['fullTime'] && {
                                        error: true
                                    })}
                                    component="fieldset"
                                    sx={{ m: 3 }}
                                    variant="standard"
                                >
                                    <label >I'm looking for help hiring</label>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={values.fullTime} name="fullTime" />
                                            }
                                            label="Full-time employees"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={values.contractors} name="contractors" />
                                            }
                                            label="Contractors"
                                        />
                                    </FormGroup>
                                    {errors['fullTime'] && <FormHelperText>This field is required</FormHelperText>}
                                </FormControl> */}
                                {/* <FormControl sx={{ px: 1 }} component="div" variant="standard">
                                    <FormLabel component="label" sx={{ fontWeight: 600, color: 'black' }}>I'm looking for help hiring</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox onChange={handleInputValue} name="fullTime" />} label="Full-time employees" />
                                        <FormControlLabel control={<Checkbox onChange={handleInputValue} name="contractor" />} label="Contractors" />
                                    </FormGroup>
                                </FormControl> */}
                            </Box>
                            <Box sx={{ py: 1 }}>
                                <FormControl component="div" sx={{ width: '100%' }}>
                                    <FormLabel component="label" sx={{ fontWeight: 600, color: 'black' }}>How many people do you plan on hiring in the next 12 months?</FormLabel>
                                    <TextField
                                        id="plan"
                                        defaultValue=""
                                        name='plan'
                                        size="small"
                                        onChange={handleInputValue}
                                        onBlur={handleInputValue}
                                        {...(errors['plan'] && {
                                            error: true,
                                            helperText: errors['plan']
                                        })}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ py: 1 }}>
                                <FormControl component="div" sx={{ width: '100%' }}>
                                    <FormLabel component="label" sx={{ fontWeight: 600, color: 'black' }}>Briefly describe the roles you need help filling</FormLabel>
                                    <TextField
                                        id="describe"
                                        name='describe'
                                        defaultValue=""
                                        sx={{ width: '100%' }}
                                        multiline
                                        maxRows={2}
                                        onChange={handleInputValue}
                                        onBlur={handleInputValue}
                                        {...(errors['describe'] && {
                                            error: true,
                                            helperText: errors['describe']
                                        })}
                                    />
                                </FormControl>
                            </Box>
                            <div>
                                <Button variant="contained" type="submit" disabled={!IsFormValid}>Submit</Button>
                            </div>
                        </form>
                    </Box>
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                </Grid>
            </Grid>
        </>
    )
}