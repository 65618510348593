import { Box, Button, Container, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import classes from "./ContactUs.module.css";

const ContactUs = () => {
  return (
    <Container maxWidth="xl">
      <Box className={classes.container_spacing}>
        <Box component="h1" className={classes.page_heading}>
          Contact us
        </Box>
        <Box component="p" className={classes.page_subHeading}>
          Get in touch and let us know how we can help.
        </Box>
        <Grid
          container
          //   spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 3 }}
          alignItems="center"
          justifyContent={"center"}
        >
          <Grid item xs={1} sm={1} md={2}>
            <Box className={classes.contact_boxes}>
              <Box component="h2" className={classes.contact_boxes_title}>
                Hiring Solutions
              </Box>
              <Box component="p" className={classes.contact_boxes_para}>
                For questions about our hiring solutions and other queries:
              </Box>
              <Box
                component="a"
                href="mailto:hr@bluedotsol.com"
                className={classes.contact_boxes_link}
              >
                hr@bluedotsol.com
              </Box> <br/>
              <Box
                component="a"
                href="tel:+16236882572"
                className={classes.contact_boxes_link}
              >
                623-688-2572
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          //   spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <Box className={classes.contact_boxes}>
              <Box component="h2" className={classes.contact_boxes_title}>
                Glendale, AZ
              </Box>
              <Box component="p" className={classes.contact_boxes_para}>
                5830 W Thunderbird Rd
              </Box>
              <Box component="p" className={classes.contact_boxes_para}>
                Suite B8 - 1119
              </Box>
              <Box component="p" className={classes.contact_boxes_mail}>
                Glendale, AZ - 85306
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <Box className={classes.contact_boxes}>
              <Box component="h2" className={classes.contact_boxes_title}>
                Austin, TX
              </Box>
              <Box component="p" className={classes.contact_boxes_para}>
                6500 River Pl Blvd
              </Box>
              <Box component="p" className={classes.contact_boxes_para}>
                Bldg 7, Suite 250
              </Box>
              <Box component="p" className={classes.contact_boxes_mail}>
                Austin, TX - 78730
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default ContactUs;
